import { Link } from 'src/shared/ng-models/Link.interface';
import { Component} from '@angular/core';

@Component({
  selector: 'mi-period-of-performance',
  template: `
  <mi-page-header title="Period Of Performance" [breadcrumbs]="breadCrumbs" [links]="headerLinks">
  </mi-page-header>
  <mi-metabase metaBaseDashboardId="112"></mi-metabase>
`,
})

export class PeriodOfPerformanceComponent  {
  breadCrumbs: Link[] = [
    {
      label: "Dashboard",
      url: "/",
      internal: true
    },
    {
      label: "Period Of Performance",
      url: "/period-of-performance",
      internal: true
    }
  ];
  headerLinks: Link[];
}
